@use "../util" as *;

.header {

    display: flex;
    flex-wrap: wrap;
    margin-top: rem(36);

  @include breakpoint(medium) {
    
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    width: 100%;

    @include breakpoint(medium) {
        width: auto;
    }
  }

  &__subtitle {
    font-size: rem(14);
    font-weight: 700;
    color: var(--text-color2);
  }

  &__toggle {
    margin-left: auto;

    
  }
}
