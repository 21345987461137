@use "../util" as *;

.card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    justify-items: start;
    gap: rem(23);

    .card__subtitle {

    }

    .card__count {
        margin-bottom: 0;

    }

    .card__count, .card__change {
        align-self: end;
    }

    img, .card__change {
        justify-self: end;
    }

    img {
        margin-right: rem(4);
    }
}