@use '../util' as *;

.container {
    padding: 0 rem(25);
    max-width: rem(1110);
    margin: 0 auto rem(36);

    @include breakpoint(large) {
        padding: 0;
    }
}

.attribution {
    display: flex;
    justify-content: center;
}
