@use "../util" as *;

.toggle {
  display: grid;
  grid-template-columns: 1fr 3rem;
  border: none;
  margin: 0;

  @include breakpoint-down(small) {
    margin-top: rem(16);
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }

  label {
    font-size: rem(14);
    font-weight: bold;
    color: var(--toggle);
  
    &[for='dark'] {
      line-height: rem(24);
      margin-right: rem(13);
    }
  }

  &__wrapper {
    position: relative;
    height: rem(24);
  }

  input[type="radio"] {
    margin: 0 rem(-2) 0 rem(-2);
    opacity: 0;
    width: rem(24);
    height: rem(24);
  }

  &__background {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    border-radius: rem(12);
    overflow: hidden;
    background: var(--toggle-bg);
    pointer-events: none;
  }

  &__button {
    position: absolute;
    left: rem(3);
    top: rem(3);
    right: 100%;
    width: rem(18);
    height: rem(18);
    border-radius: 50%;
    background-color: var(--toggle-button);
    transition: all 0.15s ease-in-out;
  }

  #light:checked ~ .toggle__button {
    left: calc(100% - 21px);
    right: 3px;
  }
}
